import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { ThemeService } from 'weavix-shared/services/themeService';
import { TooltipOptions } from 'weavix-shared/models/tooltip.model';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
    @Input() options: TooltipOptions;
    @Input() translate: boolean = true;

    lightTheme: boolean;
    show: boolean;

    constructor(
        private elRef: ElementRef,
    ) {
        this.lightTheme = ThemeService.getLightTheme();
    }

    @HostListener('document:click', ['$event'])
    clickout(event): void {
        if (this.options?.showOnClick) {
            if (!this.elRef.nativeElement.contains(event.target)) {
                this.show = false;
            }
        }
    }
}
