import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { EventService, GEOFENCE_EVENT_TYPES, mapExitToDisconnectEvent, RowEventType } from 'map/weavix-map/event-log/event.service';
import { Subject, Subscription } from 'rxjs';
import { AnyBadgeEvent, GeofenceEvent } from 'weavix-shared/models/event.model';
import { Person } from 'weavix-shared/models/person.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { Geofence } from 'weavix-shared/models/weavix-map.model';
import { AccountService } from 'weavix-shared/services/account.service';
import { AlertService, ServiceError } from 'weavix-shared/services/alert.service';
import { BadgeService } from 'weavix-shared/services/badge.service';
import { PersonService } from 'weavix-shared/services/person.service';
import { PubSubService } from 'weavix-shared/services/pub-sub.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-geofence-detail',
    templateUrl: './geofence-detail.component.html',
    styleUrls: ['./geofence-detail.component.scss', '../map-detail-view.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeofenceDetailComponent implements OnInit, OnChanges, OnDestroy {
    @Input() geofence: Geofence;
    @Input() people: Person[];
    @Output() closeOutput: EventEmitter<void> = new EventEmitter();

    allPeopleMap: Map<string, Person> = new Map();
    badgeEventSubscription: Subscription;
    peopleOverviewDataSub: Subscription;
    badgeEventsHistory: GeofenceEvent[] = [];
    rowEventTypes: RowEventType[] = [...GEOFENCE_EVENT_TYPES];
    badgeEvents: Subject<GeofenceEvent> = new Subject();
    companiesInArea: Map<string, string> = new Map();
    craftsInArea: Map<string, string> = new Map();
    loading: boolean = true;
    isError: boolean = false;

    constructor(
        private accountService: AccountService,
        private alertService: AlertService,
        private personService: PersonService,
        private pubSubService: PubSubService,
        private badgeService: BadgeService,
        private translationService: TranslationService,
        private cdr: ChangeDetectorRef,
    ) { }

    async ngOnInit() {
        await this.setup();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.geofence?.currentValue?.id !== changes.geofence?.previousValue?.id) {
            await this.setup();
            this.cdr.markForCheck();
        } else if (changes.people?.currentValue?.length !== changes.people?.previousValue?.length) {
            this.setPeopleInsideGeoStats();
            this.cdr.markForCheck();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeFromGeofenceSubs();
    }

    private async setup() {
        try {
            this.loading = true;
            this.isError = false;
            await this.setAllPeopleMap();
            this.setPeopleInsideGeoStats();
            await this.setupEventLog();
        } catch (e) {
            this.isError = true;
        } finally {
            this.loading = false;
        }
    }

    private async setAllPeopleMap() {
        try {
            const people = await this.personService.getPeople(this);
            people.forEach( p => this.allPeopleMap.set(p.id, p));
        } catch (e) {
            this.alertService.sendServiceError(e, ServiceError.Get, 'person.people');
        }
    }

    private setPeopleInsideGeoStats(): void {
        this.clearInsideGeoStats();
        this.people.forEach(p => {
            const person = this.allPeopleMap.get(p.id);
            if (person) {
                this.companiesInArea.set(person.companyId, person.companyId);
                person.crafts.forEach(c => this.craftsInArea.set(c, c));
            }
        });
    }

    private clearInsideGeoStats(): void {
        this.companiesInArea.clear();
        this.craftsInArea.clear();
    }

    private async setupEventLog() {
        try {
            this.badgeEventsHistory = await this.badgeService.getEventsByGeofence(this, this.geofence.id).then(mapExitToDisconnectEvent);
            await this.setupGeofenceSubscriptions();
        } catch (e) {
            console.error(e);
        }
    }

    private async setupGeofenceSubscriptions() {
        this.unsubscribeFromGeofenceSubs();
        await this.subscribToGeofenceEvents();
    }

    private async subscribToGeofenceEvents() {
        const account = await this.accountService.getAccount();
        if (!account) return;

        this.badgeEventSubscription = (await this.pubSubService.subscribe<AnyBadgeEvent>(this, Topic.AccountPersonBadgeEvent, [account.id, '+']))
            .subscribe(async message => {
                if (this.isGeofenceEvent(message.payload)) this.badgeEvents.next(message.payload);
                this.cdr.markForCheck();
            });
    }

    private isGeofenceEvent(event: AnyBadgeEvent) {
        const fenceId = event?.geoFenceId;
        return fenceId === this.geofence.id && EventService.isGeofenceBadgeEvent(event);
    }

    private unsubscribeFromGeofenceSubs(): void {
        this.badgeEventSubscription?.unsubscribe();
    }

    handleClose(): void {
        this.closeOutput.emit();
    }

    eventPersonNameLookUp() {
        return <T extends GeofenceEvent>(row: T) => {
            if (row.personName) {
                return row.personName;
            } else {
                const person: Person = this.allPeopleMap.get(row.personId);
                return person ? person.fullName : this.translationService.getImmediate('generics.unknown');
            }
        };
    }
}
