import { ConfinedSpaceEvent, EntryCheckin } from '../item/confined-space';
import { UserBadge } from '../user/User';
import { Channel } from '../channel/channel';
import { ChannelMessage, ChannelMessageResponse } from '../channel/channel-message';
import { FacilityAlert } from '../alert/facility-alerts';
import { NotificationCenterEntry } from '../notification/notification-center';
import { AudioData } from '../media/media';
import { VidVoxStartRingbackData } from '../vidvox/vidvox-ringback-data';

/**
 * The discriminated union of all push payloads.
 * Use `topic` to determine the type of `data`.
 */
export type PushPayload =
    | AlertPayload
    | ChannelPayload
    | FacilityAlertsPayload
    | FacilityAlertsCallPayload
    | HoleWatchPayload
    | HoleWatchSwapPayload
    | MissedPagePayload
    | NotificationCenterEntryPayload
    | VidVoxRingbackPayload

    | ChannelPagePayload
    | PttPayload
    | ReconnectPayload
    ;

export enum PushTopic {
    Alert = 'alert',
    Channel = 'channel',
    FacilityAlerts = 'facility-alert',
    FacilityAlertsCall = 'facility-alert-call',
    HoleWatch = 'hole-watch',
    HoleWatchSwap = 'hole-watch-swap',
    MissedPage = 'missed-page',
    NotificationCenterEntry = 'notification-center-entry',
    VidVoxRingback = 'vidvox-ringback',

    ChannelPage = 'channel-page',
    Ptt = 'ptt',
    Reconnect = 'reconnect',
}

/** Common fields for all push payloads. */
interface PushPayloadBase {
    acceptButtonText?: string;
    cancelButtonText?: string;
    accountId: string;
    facilityId?: string;
    date: Date;
    clear?: boolean;
    constant?: Date;
    notificationId: string;
    key: string;
    badges: UserBadge;
    badge?: number; // Backwards compat
    notification: {
        title: string;
        body: string;
    };
}

// NOTE: do not use 'any' or 'unknown' for the `topic` or `data` fields,
// as that would prevent typescript from narrowing the union.

interface AlertPayload extends PushPayloadBase {
    topic: PushTopic.Alert;
    data: object | null;
}

interface ChannelPayload extends PushPayloadBase {
    topic: PushTopic.Channel;
    data: Channel | ChannelMessageResponse;
}

interface FacilityAlertsPayload extends PushPayloadBase {
    topic: PushTopic.FacilityAlerts;
    data: FacilityAlert & { read?: Date, removed?: Date, alertLevelTranslated: string };
}

interface FacilityAlertsCallPayload extends PushPayloadBase {
    topic: PushTopic.FacilityAlertsCall;
    data: FacilityAlert & { read?: Date, removed?: Date, alertLevelTranslated: string };
}

interface HoleWatchPayload extends PushPayloadBase {
    topic: PushTopic.HoleWatch;
    data: ConfinedSpaceEvent;
}

interface HoleWatchSwapPayload extends PushPayloadBase {
    topic: PushTopic.HoleWatchSwap;
    data: EntryCheckin;
}

interface MissedPagePayload extends PushPayloadBase {
    topic: PushTopic.MissedPage;
    data: {
        id: ChannelMessage['id'],
        data: ChannelMessage['data'];
        channelId: string;
    };
}

interface NotificationCenterEntryPayload extends PushPayloadBase {
    topic: PushTopic.NotificationCenterEntry;
    data: NotificationCenterEntry;
}

interface VidVoxRingbackPayload extends PushPayloadBase {
    topic: PushTopic.VidVoxRingback;
    data: VidVoxStartRingbackData;
}

interface ChannelPagePayload extends PushPayloadBase {
    topic: PushTopic.ChannelPage;
    data: ChannelMessageResponse;
}

interface PttPayload extends PushPayloadBase {
    topic: PushTopic.Ptt;
    data: AudioData;
}

interface ReconnectPayload extends PushPayloadBase {
    topic: PushTopic.Reconnect;
    data: object;
}
