import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { TeamsService } from 'weavix-shared/services/teams.service';
import { AutoUnsubscribe, sleep } from 'weavix-shared/utils/utils';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { Modal, ModalActionType, defaultModal } from '@weavix/models/src/modal/modal';
import { environment } from 'environments/environment';

@AutoUnsubscribe()
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
/**
 * ngOnChanges does not run when created dynamically.
 * Any inputs need getter/setters to manage changes manually
 */
export class ModalComponent implements OnInit {
    private modalInputBacker: Modal;
    @Input()
    set modalInput(value: Modal) {
        this.modalInputBacker = value;
        this.updateModalInput(value);
        this.focusFirstElement(1000);
    }
    get modalInput() {
        return this.modalInputBacker;
    }
    @Input() downloadUrl: string;
    @Input() outerTitle: string;
    @Input() showClose: boolean = !this.teamsService.isTeamsApp();
    // when false modal fades out and cannot be re-used on submit
    @Input() alwaysOpen: boolean = environment.consoleApp;

    @Output() modalCloseOutput: EventEmitter<ModalActionType> = new EventEmitter();

    @ViewChild('modal') modal: ElementRef;

    modalActionType = ModalActionType;
    closing = false;

    buttonHovers: { [id: string]: boolean } = {};

    constructor(
        public teamsService: TeamsService,
        public translateService: TranslationService,
    ) { }

    @HostListener('document:keydown.escape')
    onKeydownHandler() {
        this.modalCloseOutput.emit(ModalActionType.cancel);
    }

    @HostListener('document:keydown.tab')
    checkTabFocus() {
        this.focusFirstElement(1);
    }

    @HostListener('document:keydown.shift.tab')
    checkShiftTabFocus() {
        this.focusFirstElement(1, true);
    }

    ngOnInit() { 
        
    }

    /**
     * @param sleepTime Use for init to give time for any content to render if calls had to be made
     * and then passing 1 for normal tab checks for document tab to update current element
     */
    async focusFirstElement(sleepTime: number, backwards: boolean = false) {
        await sleep(sleepTime);
        if (!this.modal) return;
        const div = this.modal.nativeElement as HTMLDivElement;
        const isInModal = div.contains(document.activeElement);
        if (!isInModal) {
            if (backwards) {
                const elements = div.querySelectorAll('input, button');
                (elements[elements.length - 1] as HTMLElement)?.focus();

            } else {
                const focusElement = div.querySelector('input, button') as HTMLElement;
                focusElement?.focus();
            }
        }
    }

    updateModalInput(modal: Modal) {
        this.modalInputBacker = _.merge(_.cloneDeep(defaultModal), modal);
    }

    async handleModalClose(action: ModalActionType): Promise<void> {
        if (!this.alwaysOpen) {
            this.closing = true;
            setTimeout(() => this.modalCloseOutput.emit(action), 10);
        } else {
            this.modalCloseOutput.emit(action);
        }
        
    }

}
