import { PermissionAction } from '@weavix/permissions/src/permissions.model';
import { GasMonitorReading, InstrumentStatus, SensorGasCode, SensorStatus } from '../gas/gas';
import { ConfinedSpacePermit, ConfinedSpacePermitResponse } from '../item/confined-space';
import { AnyItem, ItemType } from '../item/item';
import { LocationContributor } from '../location/location-update';
import { Point } from '../map/location';
import { ConnectivityStatus } from './badge-update';

export interface Event extends AbstractEvent {
    location?: [number, number];
    level?: number;
    accuracy?: number;
    offline?: boolean;
    stationary?: boolean;
    engine?: boolean;
    battery?: number;
    network?: NetworkStatus;
    connectivity?: ConnectivityStatus;
    panic?: boolean;
    message?: string;
    //gasMonitor?: GasMonitorReading;
    peers?: Array<{
        id?: string;
        distance?: number;
        rssi?: number;
    }>;
    beacons?: Array<{
        id?: string;
        distance?: number;
        rssi?: number;
    }>;
    locationDate?: Date,
    lastMoving?: Date,
    locationType?: string,
    deviceType?: 'walt' | 'android' | 'ios',
    deviceId?: string
    // image?: { // This image object can be present on events in badgeUpdates but conflicts with ImageEvent.image
    //     image?: string;
    //     thumbnail?: string;
    //     imageIndex?: string;
    //     date?: string;
    // };
    grantedLocationPermissions?: {
        fine: boolean;
        coarse: boolean;
        background: boolean;
    };
    locationDetails?:{
        method?:string,
        contributors?: LocationContributor[],
        accuracy?: number
    };
}

export interface AbstractEvent {
    id?: { itemId?: string; wilmaId?: string; personId?: string; waltId?: string; date?: Date; id?: string; }; // when event is for item, id.personId = itemId
    facilityId?: string;
    registrationId?: string; // wisp id
    type?: EventType;
    folderId?: string;
    personName?: string;
    personTags?: string[];
    personCrafts?: string[];
    personCompany?: string;
    itemName?: string;
    itemTags?: string[];
}

export interface NetworkStatus {
    wanDevices: NetworkWanDevice[];
    gps: NetworkWanGps;
    updated?: Date;
    status?: NetworkStatusType;
}

export interface NetworkWanDevice {
    id: string;
    type: NetworkWanDeviceType;
    name?: string;
    sim?: string;
    carrier?: string;
    dbm?: string;
    rfBand?: string;
    serviceType?: string;
    ssid?: string; // only for wifi wan
}

export enum NetworkWanDeviceType {
    Cellular = 'cellular',
    Ethernet = 'ethernet',
    Wifi = 'wifi',
    Unknown = 'unknown',
}

export interface NetworkWanGps {
    location: number[];
    time: string;
}

export enum NetworkStatusType {
    Disconnected = 'disconnected',
    Private = 'private',
    Verizon = 'verizon',
    Other = 'other',
}

export enum EventType {
    AirReadingSubmission = 'air-reading-submission',
    BeaconLowBattery = 'beacon-low-battery',
    BeaconDisconnect = 'beacon-disconnect',
    BeaconEnter = 'beacon-enter',
    BeaconExit = 'beacon-exit',
    ConfinedSpacePermit = 'confined-space-permit',
    EngineOff = 'engine-off',
    EngineOn = 'engine-on',
    EntryAttendantIn = 'entry-attendant-in',
    EntryAttendantOut = 'entry-attendant-out',
    EntryEnter = 'entry-enter',
    EntryEntrant = 'entry-entrant',
    EntryExit = 'entry-exit',
    EntryImage = 'entry-image',
    EntryLeft = 'entry-left',
    EntryMotion = 'entry-motion',
    EntryWilmaIn = 'entry-wilma-in',
    EntryWilmaOut = 'entry-wilma-out',
    FacilityEnter = 'facility-enter',
    FacilityExit = 'facility-exit',
    FormAnswerSubmission = 'form-answer-submission',
    FormSubmission = 'form-submission',
    GasCodeReading = 'gas-code-reading',
    GasInstrumentReading = 'gas-instrument-reading',
    GasLevelReading = 'gas-level-reading',
    GasMonitorPair = 'gas-monitor-pair',
    GasMonitorUnpair = 'gas-monitor-unpair',
    GeofenceDisconnect = 'geofence-disconnect',
    GeofenceEnter = 'geofence-enter',
    GeofenceExit = 'geofence-exit',
    LevelEnter = 'level-enter',
    LevelExit = 'level-exit',
    MessageDelivered = 'message-delivered',
    MobileLocationDenied = 'mobile-location-denied',
    MobileLogin = 'mobile-login',
    MobileLogout = 'mobile-logout',
    Moving = 'moving',
    None = 'none',
    PanicReceived = 'panic-received',
    PeerEnter = 'peer-enter',
    PeerExit = 'peer-exit',
    SetStatus = 'set-status',
    Stationary = 'stationary',
    WaltAssigned = 'walt-assigned',
    WaltDocked = 'walt-docked',
    WaltLocation = 'walt-location',
    WaltLogin = 'walt-login',
    WaltLogout = 'walt-logout',
    WaltUnassigned = 'walt-unassigned',
    WaltUndocked = 'walt-undocked',
    WilmaAssigned = 'wilma-assigned',
    WilmaBattery = 'wilma-battery',
    WilmaCharging = 'wilma-charging',
    WilmaNetworkChange = 'wilma-network-change',
    WilmaUnassigned = 'wilma-unassigned',
}

export type BadgeLocation = [number, number];

export enum WorkflowActionType {
    Alert = 'alert',
    Post = 'post',
    Form = 'form',
    Report = 'report',
    Sms = 'sms',
    Email = 'email',
    Notify = 'notify',
    Status = 'status',
    Tag = 'tag',
    FacilityAlert = 'facility-alert',
}

export interface AbstractBadgeEvent {
    id?: string;
    folderId?: string;
    facilityId?: string;
    date: string;
    personId?: string;
    personName?: string;
    registrationId?: string;
    type?: EventType;
    personCompany?: string;
    personCrafts?: string[];
    personTags?: string[];
}

export interface AirReadingSubmissionEvent extends AbstractBadgeEvent {
    // type?: EventType.AirReadingSubmission;
    airReadingSubmissionId: string;
    itemId: string;
    permitNumber: string;
    entryId: string;
}


export interface BadgeEvent extends AbstractBadgeEvent {
    location?: BadgeLocation;
    level?: number;
    accuracy?: number;
    battery?: number;
    connectivity?: ConnectivityStatus;
    panic?: boolean;
    message?: string;
    gasMonitor?: GasMonitorReading;

    itemName?: string; // Not part of backend model
    formName?: string; // Not part of backend model
}

export interface ConfinedSpacePermitEvent extends AbstractBadgeEvent {
    // type?: EventType.ConfinedSpacePermit;
    confinedSpacePermitCloseOpen: {
        closed?: Date;
        created?: Date;
        itemId: string;
        permitNumber: string;
    };
}

export interface EntryEvent extends AbstractBadgeEvent {
    // type?: EventType.EntryEnter | EventType.EntryExit | EventType.EntryAttendantIn | EventType.EntryAttendantOut | EventType.EntryEntrant;
    itemId: string;
    entryId: string;
    confinedSpacePermit: ConfinedSpacePermit;
    initiatorId: string;
    reason?: string;
    wilmaId?: string;
}

export interface WilmaEvent extends AbstractBadgeEvent {
    // type?: EventType.WilmaAssigned | EventType.WilmaUnassigned;
    itemId: string;
    entryId: string;
    wilmaId: string;
}

export interface EventUpdateResponse {
    date: Date;
    personId?: string;
    itemId?: string;
    registrationId?: string;
    gasMonitor?: GasMonitorReading;
    panic?: boolean;
    location?: Point;
    facilities?: Array<{ id: string; }>;
}

export interface FormSubmissionEvent extends AbstractBadgeEvent {
    // type?: EventType.FormSubmission;
    formSubmissionId: string;
    formId: {id: string; version: string};
    formTags: string[];
    itemId?: string;
    permitNumber?: string;
    entryId?: string;
}

export interface GasMonitorEvent extends AbstractBadgeEvent {
    // type?: EventType.GasCodeReading | EventType.GasInstrumentReading;
    instrumentStatus?: InstrumentStatus;
    sensorCode?: SensorGasCode;
    sensorStatus?: SensorStatus;
}

export interface GeofenceEvent extends AbstractBadgeEvent {
    // type?: EventType.GeofenceEnter | EventType.GeofenceExit;
    disconnect?: boolean;
    geofenceId?: string;
    geofenceName?: string;
    geofenceTags?: string[];
    geofenceEntered?: string;
    visiting?: string;
}

export interface LogoutEvent extends AbstractBadgeEvent {
    // type?: EventType.MobileLogout;
    geoFenceId?: string;
    geoFenceName?: string;
    geoFenceTags?: string[];
}

export interface PanicEvent extends AbstractBadgeEvent {
    // type?: EventType.PanicReceived;
    message?: string;
}

export interface UpdateEvent {
    date: Date;
    personId?: string;
    type?: EventType;
}

export type AnyBadgeEvent = BadgeEvent & AirReadingSubmissionEvent & FormSubmissionEvent & EntryEvent & ConfinedSpacePermitEvent &
    GeofenceEvent & PanicEvent & GasMonitorEvent & WilmaEvent & LogoutEvent;

export type EventNameLookUpOverrides = {[type in EventType]? : <T extends AnyBadgeEvent>(row: T) => string};


export interface ItemUpdate {
    items?: {[key: string]: AnyItem};
    itemTypes?: {[key: string]: ItemType};
}

export const eventTypeToPermissions: {[key in EventType]?: PermissionAction} = {
    [EventType.FormSubmission]: PermissionAction.ViewFormSubmissions,
};

export const mapExitToDisconnectEvent = (x: AnyBadgeEvent[]) => x.map(e => {
    if (e.disconnect) {
        switch (e.type) {
            case EventType.GeofenceExit:
                e.type = EventType.GeofenceDisconnect;
                break;
            case EventType.BeaconExit:
                e.type = EventType.BeaconDisconnect;
                break;
        }
    }
    return e;
});

export type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};

export type RowEventMatch = EventType | RecursivePartial<AnyBadgeEvent>;

export enum RowEventType {
    FormSubmission,

    BeaconDisconnect,
    BeaconEnter,
    BeaconExit,

    GeofenceDisconnect,
    GeofenceEnter,
    GeofenceExit,

    ConfinedSpaceEnter,
    ConfinedSpaceExit,
    ConfinedSpaceAttendantIn,
    ConfinedSpaceAttendantOut,
    ConfinedSpaceAirReading,

    MobileLogout,
    Moving,
    Stationary,
    PeerEnter,
    PeerExit,
}

export interface RowEventDefinition {
    eventType: RowEventType;
    eventTitle: string;
    iconHtml: string;
    subEvents?: RowEventDefinition[];
}

export const EVENT_MATCHES: { [key in RowEventType]: RowEventMatch } = {
    [RowEventType.FormSubmission]: EventType.FormSubmission,

    [RowEventType.BeaconDisconnect]: EventType.BeaconDisconnect,
    [RowEventType.BeaconEnter]: EventType.BeaconEnter,
    [RowEventType.BeaconExit]: EventType.BeaconExit,
    [RowEventType.PeerEnter]: EventType.PeerEnter,
    [RowEventType.PeerExit]: EventType.PeerExit,

    [RowEventType.GeofenceDisconnect]: EventType.GeofenceDisconnect,
    [RowEventType.GeofenceEnter]: EventType.GeofenceEnter,
    [RowEventType.GeofenceExit]: EventType.GeofenceExit,

    [RowEventType.ConfinedSpaceEnter]: EventType.EntryEnter,
    [RowEventType.ConfinedSpaceExit]: EventType.EntryExit,
    [RowEventType.ConfinedSpaceAttendantIn]: EventType.EntryAttendantIn,
    [RowEventType.ConfinedSpaceAttendantOut]: EventType.EntryAttendantOut,
    [RowEventType.ConfinedSpaceAirReading]: EventType.AirReadingSubmission,

    [RowEventType.MobileLogout]: EventType.MobileLogout,
    [RowEventType.Stationary]: EventType.Stationary,
    [RowEventType.Moving]: EventType.Moving,
};

export const GEOFENCE_EVENT_TYPES = [
    RowEventType.GeofenceDisconnect,
    RowEventType.GeofenceEnter,
    RowEventType.GeofenceExit,
];

export const BEACON_EVENT_TYPES = [
    RowEventType.BeaconDisconnect,
    RowEventType.BeaconEnter,
    RowEventType.BeaconExit,
];

export const CONFINED_SPACE_EVENT_TYPES = [
    RowEventType.ConfinedSpaceEnter,
    RowEventType.ConfinedSpaceExit,
    RowEventType.ConfinedSpaceAttendantIn,
    RowEventType.ConfinedSpaceAttendantOut,
    RowEventType.ConfinedSpaceAirReading,
];

export interface EventResponse {
    date: Date;
    id?: string;
    personId?: string;
    personName?: string;
    registrationId?: string;
    type?: EventType;
    facilityId?: string;
    beaconName?: string;
    beaconLevel?: number;
    geofenceId?: string;
    geofenceName?: string;
    geofenceEntered?: Date;
    disconnect?: boolean;
    message?: string;
    panic?: boolean;
    instrumentStatus?: InstrumentStatus;
    sensorCode?: SensorGasCode;
    sensorStatus?: SensorStatus;
    itemId?: string;
    itemName?: string;
    entryId?: string;
    permitNumber?: string;
    confinedSpacePermit?: ConfinedSpacePermitResponse;
    formSubmissionId?: string;
    formId?: {id: string; version: string};
    formName?: string;
    airReadingSubmissionId?: string;
    image?: string;
    imageIndex?: string;
    wilmaId?: string;
    confinedSpacePermitCloseOpen?: {itemId: string; permitNumber: string; created?: Date; closed?: Date};
    holeWatchSwap?: boolean;
    visiting?: string;
}

export interface ImageEvent {
    id?: string;
    type?: EventType.EntryMotion;
    image?: string;
    thumbnail?: string;
    video?: string | Promise<Uint8Array>;
    duration?: number;
    sequence?: number;
    encoding?: string;
    imageIndex?: string;
    itemId?: string;
    entryId?: string;
    wilmaName?: string;
    date?: string;
}
