import { Subject } from 'rxjs';

import { MapCompany } from '../models/weavix-map.model';
import { Utils } from '../utils/utils';
import { CompanyService } from './company.service';
import { StateServiceBase, StateUpdate } from './state-base.service';

export class CompanyStateService extends StateServiceBase {
    companies: Map<string, MapCompany> = new Map();
    companies$: Subject<StateUpdate<MapCompany>> = new Subject();

    constructor(
        private companyService: CompanyService,
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        this.companies = Utils.toMap(await this.companyService.getAll(c, tags));
    }

    async stop() {
        super.stop();
        this.companies.clear();
    }

    protected async setupSubscriptions(c: any) {
        return (await this.companyService.subscribeCompanyUpdates(c)).subscribe(resp => {
            const companyId = resp.topic[1];
            this.updateFromMap(this.companies, { [companyId]: resp.payload }, this.companies$);
        });
    }
}
