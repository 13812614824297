import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { getFeatureBackend } from '@weavix/domain/src/feature/getFeatureBackend';
import { cloneDeep } from 'lodash';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, shareReplay } from 'rxjs/operators';

import { AlertService } from './alert.service';
import { TranslationService } from './translation.service';

import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { Utils } from '../utils/utils';

export interface CacheContext {
    collection: string;
    maxAge: number;
}

export interface CacheEntry {
    data: any;
    expires: number;
}

export const ACCOUNT_HEADER = 'x-account-id';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    static headers: any = {};

    constructor(
        private http: HttpClient,
        private alertService: AlertService,
        private translationService: TranslationService,
        private router: Router,
        private cookieService: CookieService,
        private document: Document,
    ) {
        this.detectBackendObservable = from(this.checkFeatureBranch());
    }

    static serverTimeDifference = 0;
    static unauthenticated: () => void;

    private static cacheMap: object = {};
    private static inflightGetMap: object = {};
    static bearerToken: string = localStorage.getItem('teamsAuth');
    accountId: string;
    detectBackendObservable: Observable<void>;

    static clearCache(collection: string = null, request?: string) {
        if (collection) {
            if (HttpService.cacheMap[collection]) {
                if (request && HttpService.cacheMap[collection][request]) {
                        delete HttpService.cacheMap[collection][request];
                } else {
                    Object.keys(HttpService.cacheMap[collection]).forEach(key => {
                        delete HttpService.cacheMap[collection][key];
                    });
                }
            }
        } else {
            HttpService.cacheMap = {};
        }
    }

    setAccountId(accountId: string) {
        this.accountId = accountId;
    }

    async checkFeatureBranch() {
        if (!environment.is360Api.includes('.weavixdev.')) return;
        const fetcher = (url: string) => this.http.get<any>(url).toPromise();
        const featureUrls = await getFeatureBackend(environment.version, fetcher);
        if (featureUrls) {
            environment.pubsubApi = featureUrls.mqttUrl;
            environment.is360Api = featureUrls.apiUrl;
        }
    }

    /**
     *
     * @param component Component for auto-unsubscribing
     * @param endpoint Complete backend route to be called
     * @param query Query params
     * @param cacheContext Collection type for cacheMap
     * @param valueSort Property getter for sorting - defaults to "name"
     */
    get<T>(component: any, endpoint: string, query?: any, cacheContext?: CacheContext, valueSort: string | ((v: any) => string) = 'name'): (Observable<T> & Promise<T>) {
        return this.call(component, 'get', endpoint, query, null, null, cacheContext, valueSort);
    }

    post<T>(component: any, endpoint: string, body: any, cacheContext?: CacheContext): (Observable<T> & Promise<T>) {
        return this.call(component, 'post', endpoint, null, body, null, cacheContext);
    }

    put<T>(component: any, endpoint: string, body: any, cacheContext?: CacheContext): (Observable<T> & Promise<T>) {
        return this.call(component, 'put', endpoint, null, body, null, cacheContext);
    }

    delete<T>(component: any, endpoint: string, query?: any, cacheContext?: CacheContext): (Observable<T> & Promise<T>) {
        return this.call(component, 'delete', endpoint, query, null, null, cacheContext);
    }

    upload<T>(component: any, endpoint: string, formData: FormData, cacheContext?: CacheContext): (Observable<T> & Promise<T>) {
        return this.call(component, 'post', endpoint, null, null, formData, cacheContext);
    }

    attachmentDownload(endpoint) {
        try {
            const headers: HttpHeaders = new HttpHeaders({});
            if (environment.version === 'local' && environment.is360Api.includes('https://')) endpoint = endpoint.replace('http://', 'https://');
            // Adding blank query parameter '?noCachePlz' due to cors issues.
            this.http.get(`${endpoint}?noCachePlz`, { headers, responseType: 'blob' as 'json' }).subscribe(
                (response: any) => {
                    const dataType = response.type;
                    const binaryData = [];
                    binaryData.push(response);
                    const downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
                    downloadLink.download = endpoint.split('/').pop();
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                },
            );
        } catch (error) {
            console.error(error);
        }
    }
        
    addCsrfToken(url) {
        document.cookie.split(';').forEach(v => {
            const parts = v.split('=');
            if (parts[0].trim().toLowerCase() === 'csrftoken') {
                url += `&csrftoken=${parts[1].trim()}`;
            }
        });
        return url;
    }

    private getAccountId() {
        return Utils.getAccountId() || this.cookieService.get('accountId') || this.accountId;
    }

    private call(component: any, method: string, endpoint: string, query?: any, body?: any, formData?: any, cacheContext?: CacheContext, valueSort?: string | ((v: any) => string)): (Observable<any> & Promise<any>) {
        if (query) {
            endpoint += getQueryParamStringFromMap(query);
        }
        let headers: HttpHeaders = new HttpHeaders({});
        if (body) headers = headers.set('Content-Type', 'application/json');
        Object.keys(HttpService.headers).filter(x => HttpService.headers[x] != null).forEach(x => headers = headers.set(x, HttpService.headers[x]));
        
        if (environment.teamsApp) {
            if (HttpService.bearerToken) {
                headers = headers.set('authorization', `Bearer ${HttpService.bearerToken}`);
            }
        } else {
            document.cookie.split(';').forEach(v => {
                const parts = v.split('=');
                if (parts[0].trim().toLowerCase() === 'csrftoken') {
                    headers = headers.set('X-CSRF-TOKEN', parts[1].trim());
                }
            });
        }
        let accountId = this.getAccountId();
        if (!accountId) accountId = this.getAccountId();
        if (accountId) headers = headers.set('x-account-id', accountId);
        const options = {
            headers,
            withCredentials: environment.teamsApp ? false : true,
            observe: 'response',
        };

        if (this.checkCache(cacheContext, method, endpoint)) {
            return Utils.awaitable(of(cloneDeep(HttpService.cacheMap[cacheContext.collection][endpoint].data)));
        }

        const base = method === 'get' && HttpService.inflightGetMap[endpoint] || this.detectBackendObservable.pipe(
            concatMap(() => { // make our http call after we know which backend to use
                let host = environment.is360Api;
                if (/\/media\/(broadcaster|viewer)/.test(endpoint)) {
                    host = host.replace(/api/, 'video');
                }
                const url = `${host}${endpoint}`;

                if (body || formData)
                    return this.http[method](url, body || formData, options);
                else {
                    return this.http[method](url, options);
                }
            }),
            shareReplay({ refCount: false }),
            map(data => cloneDeep(data)),
        );
        if (method === 'get') {
            HttpService.inflightGetMap[endpoint] = base;
            base.subscribe(() => delete HttpService.inflightGetMap[endpoint], () => delete HttpService.inflightGetMap[endpoint]);
        }

        const fallback = Utils.awaitable(Utils.safeSubscribe<any>(component, base
            .pipe(map((resp: HttpResponse<any>) => {
                let result = resp.body;
                if (resp.headers['date']) {
                    try {
                        HttpService.serverTimeDifference = new Date().getTime() - new Date(resp.headers['date']).getTime();
                    } catch (e) {
                        // Ignore
                    }
                }
                if (method === 'get' && Array.isArray(result) && valueSort !== null) {
                    result = Utils.sortAlphabetical(result, valueSort);
                }
                if (cacheContext) {
                    if (method === 'get') {
                        HttpService.cacheMap[cacheContext.collection][endpoint] = { data: result, expires: new Date().getTime() + cacheContext.maxAge };
                    } else {
                        HttpService.clearCache(cacheContext.collection);
                    }
                }
                 
                console.info(method, endpoint, JSON.stringify(query), JSON.stringify(body), resp.status, JSON.stringify(result));
                if (result?.['csrfToken']) this.setCookie('csrfToken', result['csrfToken']);
                return result;
            }), catchError((err: HttpErrorResponse) => {
                 
                console.info(method, endpoint, JSON.stringify(query), JSON.stringify(body), err, err.status, JSON.stringify(err.error));
                if (err.status >= 400) {
                    if (err.status === 401) {
                        if (err?.error?.message === 'ACCOUNT_DISABLED') {
                            this.alertService.sendError(err, 'ERRORS.LOGIN.ACCOUNT_DISABLED');
                            this.alertService.skipError();
                        }
                        if (!this.router.url.startsWith('/login')) {
                            HttpService.clearCache();

                            if (HttpService.unauthenticated) {
                                HttpService.unauthenticated();
                            } else {
                                // Parse returnApp with Regex and pass along. Not available in ActivatedRoute snapshot
                                const returnApp = this.document.location.href.match(/returnApp=([\w]+)/)?.[1];
                                const extras: NavigationExtras = returnApp ? { queryParams: { returnApp } } : {};

                                console.log('navigating to login because 401');
                                this.router.navigate(['login', 'authorize'], extras);
                            }
                        }

                    } else if (err.status === 403) {
                        const target = err?.error?.details?.target;
                        const action = err?.error?.details?.action;
                        if (target && action) {
                            this.alertService.sendError(err, 'ERRORS.GENERIC.FORBIDDEN-DETAIL', {
                                action: this.translationService.getImmediate(`group.action.${action}`).toLowerCase(),
                                target: this.translationService.getImmediate(`group.target.${target}`).toLowerCase(),
                            });
                        } else {
                            this.alertService.sendError(err, 'ERRORS.GENERIC.FORBIDDEN');
                        }
                        this.alertService.skipError();
                    } else {
                        console.error(err, endpoint, body);
                    }
                    return throwError(err);
                }
                return throwError(err);
            }))));

        return fallback;
    }

    private checkCache(cacheContext: CacheContext, method: string, endpoint: string): boolean {
        if (!cacheContext) return false;

        if (method !== 'get') return false;

        if (!HttpService.cacheMap[cacheContext.collection]) HttpService.cacheMap[cacheContext.collection] = {};

        if (!HttpService.cacheMap[cacheContext.collection][endpoint]) return false;
        if (HttpService.cacheMap[cacheContext.collection][endpoint].expires < new Date().getTime()) return false;

        return true;
    }

    setCookie(name: string, id: string) {
        const domainSplit = location.hostname.split('.').reverse();
        const domain = domainSplit[0] !== 'localhost' ? `.${domainSplit[1]}.${domainSplit[0]}` : null;
        const secure = domain != null;
        const expiresDays = 365;
        this.cookieService.set(name, id, expiresDays, '/', domain, secure, 'Strict');
    }
}

export function getQueryParamStringFromMap(query): string {
    let params = '';
    const queryKeys = Object.keys(query).filter(x => query[x] != null && (!Array.isArray(query[x]) || query[x].length > 0)); // Remove keys with empty values
    if (queryKeys.length > 0) {
        params += `?${queryKeys.map(q => `${encodeURIComponent(q)}=${encodeURIComponent(formatQueryParamValue(query[q]))}`).join('&')}`;
    }
    return params;
}

function formatQueryParamValue(val: any) {
    if (val instanceof Date) return val.toISOString();
    if (Array.isArray(val) && typeof val[0] !== 'object') return val.join(',');
    if (typeof val === 'object') return JSON.stringify(val);
    return val;
}
